import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  

  const BASE_URL = 'http://124.158.124.84:8055';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, {
        email,
        password,
      });

      sessionStorage.setItem('token', response.data.data.access_token);

      window.dispatchEvent(new Event('storage'));

      navigate('/details/as500');
    } catch (error) {
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-blue-600 text-center mb-8">Нэвтрэх</h2>

        {error && <p className="text-red-500 text-center">{error}</p>}

        <form onSubmit={handleSubmit}>
          {/* Username/Email Input */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
              Имэйл эсвэл хэрэглэгчийн нэр
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Та өөрийн имэйл эсвэл хэрэглэгчийн нэрээ оруулна уу"
            />
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-semibold text-gray-700">
              Нууц үг
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Та нууц үгээ оруулна уу"
            />
          </div>

          {/* Login Button */}
          <button
            type="submit"
            className="w-full py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Нэвтрэх
          </button>
        </form>

        {/* Sign Up Link */}
        <p className="mt-4 text-center">
          Хэрэв та бүртгэлгүй бол <Link to="/signup" className="text-blue-600 hover:underline">Бүртгүүлэх</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
