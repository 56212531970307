import React from 'react';
import './TeamMember.css';

const TeamMember = ({ name, role, image }) => {
    return (
        <div className="team-member-custom-card">
            <img 
                src={image} 
                alt={name} 
                className="team-member-custom-image"
            />
            <div className="team-member-custom-info">
                <h4 className="team-member-custom-name">
                    {name}
                </h4>
                <p className="team-member-custom-role">
                    {role}
                </p>
            </div>
        </div>
    );
};

export default TeamMember;
