import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './styles.css';

import Navbar from './Components/Navbar/Navbar';
import Homepage from './Components/Section/Нүүр/Homepage';
import ProductSection from './Components/Section/Бүтээгдэхүүн/ProductSection';
import ExperienceGrid from './Components/Section/Туршлагууд/ExperienceGrid';
import PricingGrid from './Components/Section/Үйлчилгээний төлбөр/PricingGrid';
import TeamGrid from './Components/Section/Манай Баг/TeamGrid';
import Footer from './Components/Footer/Footer';
import ProductAS500 from './Components/Section/Бүтээгдэхүүн/Product(AS500)';
import Login from './Components/Section/Login/Login';
import SignUp from './Components/Section/Login/SignUp';

function App() {
  const location = useLocation();
  const offsetValue = -80; // Apply the same offset here

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.replace('#', '');
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const yOffset = offsetValue;
        const yPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <Homepage />
            <ProductSection />
            <ExperienceGrid />
            <PricingGrid />
            <TeamGrid />
          </>
        } />
        <Route path="/details/as500" element={<ProductAS500 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
