import React from "react";
import "./Homepage.css";

function HomePage() {
    return (
        <div id="home" className="home-page">
            <div className="home-page-content">
                <h1>АДУУЧИН АПП</h1>
                <p>технологийн хөгжлийг амьдралд хэвшүүлье.</p>
            </div>
        </div>
    );
}

export default HomePage;
