import React from 'react';
import './PricingGrid.css';

const PricingGrid = () => {
    return (
        <div className="pricing-grid-wrapper">
            <h2 className="pricing-title">СИСТЕМ, ҮЙЛЧИЛГЭЭНИЙ ТӨЛБӨР</h2>
            <div className="pricing-grid-container">
                <div className="pricing-card">
                    <h3>3 сар</h3>
                    <p>80,000₮</p>
                </div>
                <div className="pricing-card">
                    <h3>6 сар</h3>
                    <p>130,000₮</p>
                </div>
                <div className="pricing-card">
                    <h3>12 сар</h3>
                    <p>200,000₮</p>
                </div>
            </div>
        </div>
    );
}

export default PricingGrid;
