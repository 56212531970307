// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-member-custom-card {
    background-color: #e6e6e6;
    border-radius: 12px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    max-width: 320px;
    margin: 10px;
}

.team-member-custom-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.team-member-custom-info {
    padding: 10px;
}

.team-member-custom-name, .team-member-custom-role {
    margin: 0;
    padding: 10px 0;
    font-family: 'Gilroy', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Section/Манай Баг/TeamMember.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".team-member-custom-card {\n    background-color: #e6e6e6;\n    border-radius: 12px;\n    overflow: hidden;\n    text-align: center;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 20px;\n    max-width: 320px;\n    margin: 10px;\n}\n\n.team-member-custom-image {\n    width: 100%;\n    height: 280px;\n    object-fit: cover;\n    border-radius: 8px 8px 0 0;\n}\n\n.team-member-custom-info {\n    padding: 10px;\n}\n\n.team-member-custom-name, .team-member-custom-role {\n    margin: 0;\n    padding: 10px 0;\n    font-family: 'Gilroy', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
