import React from "react";
import zurag1 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag1.jpg';
import zurag2 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag2.jpg';
import zurag3 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag3.jpg';
import zurag4 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag4.jpg';
import zurag5 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag5.jpg';
import zurag6 from '../../../assets/Зураг/Туршлагийн зураг/Aduun zurag8.jpg';
import './ExperienceGrid.css';

const experiences = [
    { id: 1, image: zurag1, alt: 'Horse in Snow' },
    { id: 2, image: zurag2, alt: 'Horses Grazing' },
    { id: 3, image: zurag3, alt: 'Horse in Field' },
    { id: 4, image: zurag4, alt: 'Sheep on Hillside' },
    { id: 5, image: zurag5, alt: 'Two Horses' },
    { id: 6, image: zurag6, alt: 'Dambii ahiin zurag'}
];

function ExperienceGrid() {
    return (
        <section id="experiments-section" className="experience-grid">
            <h2 className="section-title">ЗУРГИЙН ЦОМОГ</h2>
            <div className="experience-grid-container">
                {experiences.map(exp => (
                    <div key={exp.id} className="experience-card">
                        <img src={exp.image} alt={exp.alt} className="experience-image" />
                    </div>
                ))}
            </div>
            <div className="view-more">
                {/* Add "View More" button or additional functionality here */}
            </div>
        </section>
    );
}

export default ExperienceGrid;
