import React, { useState, useEffect } from 'react';
import axios from 'axios';
import deviceImage from '../../../assets/Зураг/Төхөөрөмжийн зураг/AS500.png'; 
import deviceImage2 from '../../../assets/Зураг/Төхөөрөмжийн зураг/Maliin gps2.jpg';
import deviceImage3 from '../../../assets/Зураг/Төхөөрөмжийн зураг/GPs iin uut.jpg';
import { FaBatteryEmpty } from "react-icons/fa6";
import { IoHardwareChip } from "react-icons/io5";
import { MdOutlineSecurity } from "react-icons/md";
import { AiOutlineGlobal } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

function Product() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const [selectedService, setSelectedService] = useState(200000);
  const [selectedImage, setSelectedImage] = useState(deviceImage); 
  const devicePrice = 360000;
  const collarPrice = 30000;
  const totalPrice = devicePrice + collarPrice + selectedService;

  const images = [deviceImage, deviceImage2, deviceImage3];

  // const handleOrder = async () => {
  //   const token = sessionStorage.getItem('token'); // Get token from session storage
  //   if (!token) {
  //     alert('Please log in first.');
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       'http://124.158.124.84:8055/items/order',
  //       {
  //         cnt: 1,
  //         price: totalPrice,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     alert('Your order is complete!');
  //   } catch (error) {
  //     console.error('Order failed', error);
  //     alert('Failed to complete the order. Please try again.');
  //   }
  // };

  const handleOrder = () => {
    // Redirect to Facebook page
    window.location.href = "https://www.facebook.com/aduuchingps"; // Facebook link
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <div className="bg-white p-6 shadow-md rounded-md relative">
            <img src={selectedImage} alt="Product" className="w-full h-80 object-contain" />

            <div className="absolute top-1/2 left-2 transform -translate-y-1/2">
              <button
                onClick={() =>
                  setSelectedImage(
                    images[(images.indexOf(selectedImage) - 1 + images.length) % images.length]
                  )
                }
                className="bg-white p-2 rounded-full shadow hover:bg-gray-200"
              >
                &#8249;
              </button>
            </div>
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
              <button
                onClick={() =>
                  setSelectedImage(images[(images.indexOf(selectedImage) + 1) % images.length])
                }
                className="bg-white p-2 rounded-full shadow hover:bg-gray-200"
              >
                &#8250;
              </button>
            </div>

            <div className="thumbnail-gallery mt-6 flex gap-2 justify-center">
              {images.map((img, idx) => (
                <button
                  key={idx}
                  onClick={() => setSelectedImage(img)}
                  className={`border-2 p-1 rounded-md ${selectedImage === img ? 'border-blue-500' : 'border-gray-300'}`}
                >
                  <img src={img} alt={`Thumbnail ${idx}`} className="w-16 h-16 object-contain" />
                </button>
              ))}
            </div>
          </div>

          <div className="mt-6">
            <h1 className="text-2xl md:text-[28px] font-bold text-blue-900">Давуу тал:</h1>
            <ul className="mt-4 space-y-4">
              <li className="bg-white p-6 rounded-lg shadow-md flex items-center gap-4">
                <div className="p-3 bg-orange-100 rounded-full">
                  <AiOutlineGlobal className="text-orange-500" size={30} />
                </div>
                <div>
                  <h2 className="text-lg font-semibold text-blue-800 md:text-[24px]">Дэлхийн Шилдэг Технологийн Хамгаалалт</h2>
                  <p className="text-lg text-gray-700 md:text-[18px]">
                  Intel, Quectel, Unisoc зэрэг дэлхийн брэндээр тоноглогдсон.
                  </p>
                </div>
              </li>
              <li className="bg-white p-6 rounded-lg shadow-md flex items-center gap-4">
                <div className="p-3 bg-orange-100 rounded-full">
                  <MdOutlineSecurity className="text-orange-500" size={30} />
                </div>
                <div>
                  <h2 className="text-lg font-semibold text-blue-800 md:text-[24px]">Ажиллах Орчин</h2>
                  <p className="text-lg text-gray-700 md:text-[18px]">
                  Эрс тэс уур амьсгалд ажиллахаар зориулагдсан тоос, усны хамгаалалттай. IP69K болон IP67 сертификаттай..
                  </p>
                </div>
              </li>
              <li className="bg-white p-6 rounded-lg shadow-md flex items-center gap-4">
                <div className="p-3 bg-orange-100 rounded-full">
                  <IoHardwareChip className="text-orange-500" size={30} />
                </div>
                <div>
                  <h2 className="text-lg font-semibold text-blue-800 md:text-[24px]">Үндэсний Сүлжээг Дэмжинэ</h2>
                  <p className="text-lg text-gray-700 md:text-[18px]">
                  Unitel, Mobicom, Skytel ийн сүлжээнд зэрэг ажиллана
                  </p>
                </div>
              </li>
              <li className="bg-white p-6 rounded-lg shadow-md flex items-center gap-4">
                <div className="p-3 bg-orange-100 rounded-full">
                  <FaBatteryEmpty className="text-orange-500" size={30} />
                </div>
                <div>
                  <h2 className="text-lg font-semibold text-blue-800 md:text-[24px]">Зайгаа Удаан Барина</h2>
                  <p className="text-lg text-gray-700 md:text-[18px]">
                  Хуруу зайтай тул солиход хялбар өдөрт нэг удаа мэдээлэл авбал 8 хүртэл жил зайгаа барина. 
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-12">
            <h1 className="text-2xl md:text-[28px] font-bold text-blue-900 mb-6">Үзүүлэлт:</h1>
            <div className="bg-white p-6 shadow-md rounded-md">
              <h2 className="text-xl md:text-[24px] font-semibold text-blue-800 mb-4">Техникийн үзүүлэлт</h2>
              <p className="text-lg text-gray-700 mb-2">
                <span className="font-semibold">Name: </span>
                FMC920-QJIBO: Quectel EG915U-EU with Teltonika TM2500
                <br />
                FMC920-QKIBO: Quectel EG915U-LA with Teltonika TM2500
              </p>
              <p className="text-lg text-gray-700 mb-6">
                <span className="font-semibold">Сүлжээ: </span>
                4G LTE CAT1 (Unitel, Mobicom, Skytel)
              </p>
              <hr className="my-4 border-t border-gray-300" />
              <h2 className="text-xl md:text-[24px] font-semibold text-blue-800 mb-4">GNSS</h2>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">GNSS: </span>
                GPS & GLONASS
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Receiver: </span>
                33 tracking channels, 99 acquisition channels
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Tracking sensitivity: </span> -165 dBM
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Position Accuracy: </span> {'< 2.5m CEP'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Velocity Accuracy: </span> {'< 0.1m/s (within +/- 15% error)'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Хэмжээ: </span> {'118 mm × 65 mm × 28 mm'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Жин: </span> {'Баттерайтэй 171гр'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Ажиллах температур: </span> {' -40 өөс 60'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Чичиргээ: </span> {'MIL-STD-810H'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Чийгшил: </span> {'95%RH @ 50°C конденсацигүй'}
              </p>
              <p className="text-lg text-gray-700">
                <span className="font-semibold">Батерей: </span> {'Li-FeS2 Төрлийн 3н ширхэг хуруу зай'}
              </p>
            </div>
          </div>
        </div>

        {/* Pricing and Order Section */}
        <div className="w-full md:w-1/3">
          <div className="bg-white p-6 shadow-md rounded-md border border-blue-500 sticky top-[90px]">
            <h1 className="text-2xl font-semibold text-blue-700 mb-2">
              Long Standby Asset Tracker AS500
            </h1>
            <p className="text-lg text-gray-700 mb-1">
              Төхөөрөмж: <span className="font-bold">360,000₮</span>
            </p>
            <p className="text-lg text-gray-700 mb-4">
              Хүзүүвч (1 ширхэг): <span className="font-bold">30,000₮</span>
            </p>

            {/* Service Selection */}
            <div className="bg-gray-100 p-4 rounded-lg mb-4 border border-gray-300">
              <p className="text-md text-gray-700 mb-2 font-semibold">Үйлчилгээний эрх:</p>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="service"
                  value={200000}
                  checked={selectedService === 200000}
                  onChange={() => setSelectedService(200000)}
                  className="mr-2"
                />
                12 сар: 200,000₮
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="service"
                  value={130000}
                  checked={selectedService === 130000}
                  onChange={() => setSelectedService(130000)}
                  className="mr-2"
                />
                6 сар: 130,000₮
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="service"
                  value={80000}
                  checked={selectedService === 80000}
                  onChange={() => setSelectedService(80000)}
                  className="mr-2"
                />
                3 сар: 80,000₮
              </label>
            </div>

            {/* Total Price */}
            <div className="text-xl font-bold text-black mb-6">
              Нийт: {totalPrice.toLocaleString()}₮
            </div>

            {/* Buttons */}
            <div className="space-y-2">
              <button className="flex items-center justify-center w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition">
                <BsTelephone className="w-5 h-5 mr-2" />
                8055-8055
              </button>
              <button 
                onClick={handleOrder} 
                className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition"
              >
                Захиалга өгөх
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Product;
