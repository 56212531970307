import React from 'react';
import Slider from 'react-slick';
import TeamMember from './TeamMember.js';
import './TeamGrid.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import zurag1 from '../../../assets/Зураг/Багийн зураг/Ankhaa ah.jpg';
import zurag2 from '../../../assets/Зураг/Багийн зураг/Buya ah.jpg';
import zurag3 from '../../../assets/Зураг/Багийн зураг/Dambii ah.jpg';
import zurag4 from '../../../assets/Зураг/Багийн зураг/Yura.jpg';
import zurag5 from '../../../assets/Зураг/Багийн зураг/T1 telmen.jpg';
import zurag6 from '../../../assets/Зураг/Багийн зураг/Dulguun.jpg';
import zurag7 from '../../../assets/Зураг/Багийн зураг/Suhee ah.jpg';

const teamMembers = [
    { name: 'Б.Анхбаяр', role: 'Төслийн менежер', image: zurag1 },
    { name: 'Б. Буянжаргал', role: 'Борлуулалтын менежер', image: zurag2 },
    { name: 'С.Дамбийжанцан', role: 'Төслийн инженер', image: zurag3 },
    { name: 'Б.Сүхбат', role: 'Инженер', image: zurag7 },
    { name: 'Э.Мөнх-Юра', role: 'Электроникийн инженер', image: zurag4 },
    { name: 'Г.Тэлмэн', role: 'Программын инженер', image: zurag5 },
    { name: 'Ш. Дөлгөөн', role: 'Өгөгдлийн шинжээч', image: zurag6 },
];

const TeamGrid = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        easing: 'ease-in-out',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div id="team-section" className="team-grid-wrapper">
            <h2 className="team-grid-title">БАГ ХАМТ ОЛОН</h2>
            <Slider {...settings} className="team-grid-container">
                {teamMembers.map((member, index) => (
                    <TeamMember 
                        key={index} 
                        name={member.name} 
                        role={member.role} 
                        image={member.image} 
                    />
                ))}
            </Slider>
        </div>
    );
};

export default TeamGrid;
