import React from "react";
import device from '../../../assets/Зураг/Төхөөрөмжийн зураг/AS500.png';
import './ProductSection.css';
import { IoPlayOutline } from "react-icons/io5";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function ProductSection() {
    return (
        <section id="product-section" className="product-section">
            <div className="product-image">
                <img src={device} alt="AS500 Product" />
            </div>
            <div className="product-details">
                <h1 className="product-title">AS500</h1>
                <p className="product-description">
                    Таны үнэт хөрөнгийг хамгаалах GPS төхөөрөмж Intel, Quectel, Unisoc зэрэг дэлхийн брэндээр тоноглогдсон. Эрс тэс уур амьсгалд ажиллахаар зориулагдсан тоос, усны хамгаалалттай. IP69K болон IP67 сертификаттай.
                </p>
                <div className="product-buttons">
                    <Link to="/details/as500" className="main-button">Дэлгэрэнгүй</Link>
                    {/* <Link to="" className="outline-button">Бичлэг <IoPlayOutline className="play-icon" /></Link> */}
                </div>
            </div>
        </section>
    );
}

export default ProductSection;
