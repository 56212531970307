import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import aduuchin_logo from '../../assets/Зураг/Logo/Navbar_logo.png';
import { SlLocationPin } from "react-icons/sl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const offsetValue = -80; // Offset value for scrolling

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = sessionStorage.getItem('token');
      setIsLoggedIn(!!token);
    };

    checkLoginStatus();

    window.addEventListener('storage', checkLoginStatus);

    return () => {
      window.removeEventListener('storage', checkLoginStatus);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to handle navigation and close the menu
  const handleNavClick = (sectionId) => {
    setMenuOpen(false); // Close the menu when an item is clicked
    if (location.pathname !== '/') {
      navigate(`/#${sectionId}`);
    } else {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const yOffset = offsetValue; // Apply the offset
        const yPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      <div className="bg-[#0d3a61] text-white text-sm px-4 py-2 flex justify-between items-center w-full">
        <span className="flex items-center">
          <SlLocationPin className="mr-2" />
          Хан-Уул дүүрэг, 3-р хороолол, Алтаргана хотхон, 1-р давхар SmartGPS
        </span>
        <span>Утасны дугаар: 7719-7719, 8055-8055</span>
      </div>
      <header className="sticky top-0 z-50 bg-white shadow-md w-full">
        <nav className="flex justify-between items-center px-4 py-4 bg-white relative w-full">

          {/* Hamburger icon for mobile */}
          <div className="md:hidden order-1">
            <button onClick={toggleMenu}>
              {menuOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
            </button>
          </div>

          {/* Logo */}
          <div className="logo">
            <Link to="/">
              <img src={aduuchin_logo} alt="logo" className="h-10 md:h-12 cursor-pointer" />
            </Link>
          </div>

          {/* Full menu for desktop */}
          <ul className={`md:flex md:space-x-8 ${menuOpen ? 'block' : 'hidden'} flex-col md:flex-row absolute md:static top-16 left-0 right-0 bg-white md:bg-transparent w-full md:w-auto z-40`}>
            <li className="py-2 px-4 md:py-0">
              <span onClick={() => handleNavClick('product-section')} className="block text-black font-medium hover:text-blue-600 cursor-pointer">
                Бүтээгдэхүүн
              </span>
            </li>
            <li className="py-2 px-4 md:py-0">
              <span onClick={() => handleNavClick('experiments-section')} className="block text-black font-medium hover:text-blue-600 cursor-pointer">
                Зургийн цомог
              </span>
            </li>
            <li className="py-2 px-4 md:py-0">
              <Link to="/details/as500" onClick={() => setMenuOpen(false)} className="block text-black font-medium hover:text-blue-600">
                Захиалга
              </Link>
            </li>
            <li className="py-2 px-4 md:py-0">
              <span onClick={() => handleNavClick('team-section')} className="block text-black font-medium hover:text-blue-600 cursor-pointer">
                Манай баг
              </span>
            </li>

            {/* Login button for mobile */}
            {!isLoggedIn && (
              <li className="py-2 px-4 md:py-0">
                <Link to="/login" onClick={() => setMenuOpen(false)} className="text-[#196FAF] px-4 py-2 rounded-md hover:bg-[#196FAF] hover:text-white transition block md:hidden border border-[#196FAF] text-center">
                  Нэвтрэх
                </Link>
              </li>
            )}
          </ul>

          {/* Show login button or profile icon on desktop */}
          <div className="hidden md:flex">
            {isLoggedIn ? (
              <div className="flex items-center space-x-4">
                <FontAwesomeIcon icon={faUser} className="text-xl text-[#196FAF]" />
              </div>
            ) : (
              <Link to="/login" className="text-[#196FAF] border border-[#196FAF] px-4 py-2 rounded-md hover:bg-[#196FAF] hover:text-white transition hidden md:inline-block">
                Нэвтрэх
              </Link>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
