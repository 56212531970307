import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();
  const BASE_URL = 'http://124.158.124.84:8055';
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const fixedRoleId = '7ef41cf0-7a77-4025-a00a-ff7899fb0be7';

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Нэр шаардлагатай'),
    last_name: Yup.string().required('Овог шаардлагатай'),
    email: Yup.string()
      .required('Имэйл шаардлагатай')
      .matches(/^[^@]+@gmail\.com$/, 'Зөв имэйл оруулна уу'),
    phone: Yup.string()
      .matches(/^[0-9]{8,}$/, 'Утасны дугаар буруу байна (8 оронтой байх ёстой)')
      .required('Утасны дугаар шаардлагатай'),
    password: Yup.string()
      .min(8, 'Нууц үг хамгийн багадаа 8 тэмдэгт байх ёстой')
      .required('Нууц үг шаардлагатай'),
  });

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
  };

  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    try {
      setErrors({});
      const formData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        role: fixedRoleId,
      };

      // Sign-up user
      await axios.post(`${BASE_URL}/users`, formData);
      setSuccess(true);

      // Automatically log in user after sign-up
      const loginResponse = await axios.post(`${BASE_URL}/auth/login`, {
        email: values.email,
        password: values.password,
      });

      // Store the token and redirect
      sessionStorage.setItem('token', loginResponse.data.data.access_token);
      resetForm();
      navigate('/details/as500'); // Redirect to product page

    } catch (error) {
      console.log('API Error:', error);
      setErrors({ submit: 'Sign-up failed. Please check your inputs or try again later.' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-blue-600 text-center mb-8">Бүртгүүлэх</h2>

        {success && (
          <div className="text-green-500 text-center mb-4">
            Бүртгэл амжилттай боллоо! Удалгүй бүтээгдэхүүний хуудас руу шилжиж байна...
          </div>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              {/* First Name */}
              <div className="mb-4">
                <label htmlFor="first_name" className="block text-sm font-semibold text-gray-700">Нэр</label>
                <Field
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Та нэрээ оруулна уу"
                />
                <ErrorMessage name="first_name" component="div" className="text-red-500" />
              </div>

              {/* Last Name */}
              <div className="mb-4">
                <label htmlFor="last_name" className="block text-sm font-semibold text-gray-700">Овог</label>
                <Field
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Та овгоо оруулна уу"
                />
                <ErrorMessage name="last_name" component="div" className="text-red-500" />
              </div>

              {/* Email */}
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-semibold text-gray-700">Имэйл</label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Та имэйлээ оруулна уу"
                />
                <ErrorMessage name="email" component="div" className="text-red-500" />
              </div>

              {/* Phone */}
              <div className="mb-4">
                <label htmlFor="phone" className="block text-sm font-semibold text-gray-700">Утасны дугаар</label>
                <Field
                  type="text"
                  name="phone"
                  id="phone"
                  className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Та утасны дугаараа оруулна уу"
                />
                <ErrorMessage name="phone" component="div" className="text-red-500" />
              </div>

              {/* Password */}
              <div className="mb-6">
                <label htmlFor="password" className="block text-sm font-semibold text-gray-700">Нууц үг</label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  className="w-full p-2 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Та нууц үгээ оруулна уу"
                />
                <ErrorMessage name="password" component="div" className="text-red-500" />
              </div>

              {/* Submission error */}
              {errors.submit && <div className="text-red-500 mb-4">{errors.submit}</div>}

              {/* Sign-Up Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300 ${isSubmitting ? 'opacity-50' : ''}`}
              >
                {isSubmitting ? 'Бүртгүүлж байна...' : 'Бүртгүүлэх'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignUp;
